import { Form } from 'react-bootstrap';


const BsalePriceListSelector = (props) => {
  return (
    <Form.Select name='price_list_id' onChange={props.onChange} value={props.selected} >
      <option>Seleccionar</option>
      {
        props.price_lists.map((price_list) => {
          return (
            <option
              key={price_list.id}
              value={price_list.id}
            >
                {price_list.name}
            </option>
          );
        })
      }
    </Form.Select>
  );
}

export default BsalePriceListSelector;
