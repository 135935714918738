import React from 'react';

import IntegrationsGetter from '../services/integrationsgetter';


function withIntegrationsGetter(WrappedComponent) {

  // ...and returns another component...
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        integrations: [],
        isLoading: true
      };
    }

    setLoading = () => { this.setState({ isLoading: true }) }
    setDone = (integrations) => { this.setState({ isLoading: false, integrations }) }

    onUpdateIntegrations = async () => {
      this.setLoading();
      const config_getter = new IntegrationsGetter(this.props.access_token);
      const integrations = await config_getter.list()
      this.setDone(integrations);
    }

    componentDidUpdate(prevProps) {
      if (this.props.access_token !== prevProps.access_token) {
        this.onUpdateIntegrations();
      }
    }

    render = () => {
      return (<WrappedComponent {...this.state} {...this.props} onUpdateIntegrations={this.onUpdateIntegrations} />)
    }
  };
}


export default withIntegrationsGetter;
