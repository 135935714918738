import React from 'react';

import IntegrationsCreator from '../services/integrationscreator';


function withIntegrationsCreator(WrappedComponent) {

  // ...and returns another component...
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isProcessing: false };
    }

    setProcessing = () => this.setState({ isProcessing: true });

    setDone = () => this.setState({ isProcessing: false });

    onCreate = async (integration) => {
      this.setProcessing();
      const integrations_creator = new IntegrationsCreator(this.props.access_token);
      await integrations_creator.create(integration);
      this.setDone();
    }

    onUpdate = async (integration) => {
      this.setProcessing();
      const integrations_creator = new IntegrationsCreator(this.props.access_token);
      await integrations_creator.update(integration);
      this.setDone();
    }

    onDelete = async (integration) => {
      const integrations_creator = new IntegrationsCreator(this.props.access_token);
      await integrations_creator.delete(integration);
      this.setDone();
    }

    render = () => {
      return (
        <WrappedComponent
          isProcessing={this.state.isProcessing}
          onCreate={this.onCreate}
          onUpdate={this.onUpdate}
          onDelete={this.onDelete}
          {...this.props}
        />
      );
    }
  };
}


export default withIntegrationsCreator;
