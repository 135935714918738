import { Form } from 'react-bootstrap';


function BsaleHook(props) {
  return (
    <>
      <Form.Control
        onFocus={(e) => e.target.select()}
        readOnly={true}
        type="text"
      value={`https://lpiproduct-o7untflc2a-uc.a.run.app/bsale/hook/listener/${props.site_name}`}
      ></Form.Control>
      <Form.Text className="text-muted">
        Enviar mensaje por slack a
        <a href="https://bsale-io.slack.com/archives/DLS5Z4TT7" target={"_blank"} rel="noreferrer" >@Esteban</a>
        con el link indicado y el RUT del cliente indicando que se solicita crear un hook
        sin reemplazar el que ya existe para la integración de inventario.
        <br />
        <b>cpn id: </b> {props.company.id}<br/>
        <b>empresa: </b>{props.company.name}<br/>
        <b>rut: </b> {props.company.code}<br/>
        <b>país: </b> {props.company.country}<br/>
      </Form.Text>
    </>
  );
}

export default BsaleHook;
