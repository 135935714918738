import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import { useState } from 'react';

import {
  Container,
  Row,
  Button,
  Navbar,
  Offcanvas
} from 'react-bootstrap';

import IntegrationList from './components/integrationlist';
import ConfigForm from './components/configform';
import withIntegrationsGetter from './containers/withintegrationgetter';
import withIntegrationsCreator from './containers/withintegrationscreate';
import withLoginInitiator from './containers/withlogininitiator';


function App(props) {
  const [show, setShow] = useState(false);
  const [integration, setIntegration] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (integration) => {
    setIntegration(integration);
    setShow(true);
  };
  const handleSave = () => {
    handleClose();
    props.onUpdateIntegrations();
  };

  const onCreate = async (integration) => {
    await props.onCreate(integration);
    handleSave();
  };

  const onUpdate = async (integration) => {
    await props.onUpdate(integration);
    handleSave();
  };

  const onDelete = async (integration) => {
    await props.onDelete(integration);
    handleSave();
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
        <Container>
          <Navbar.Brand href="#home">Integración de Productos</Navbar.Brand>
          <Button
            className='float-end'
            onClick={() => {
              if (props.isLoggedIn)
                handleShow({});
              else
                props.initLogin();
            }}
          >{ props.isLoggedIn ? "+": "Login"}</Button>
        </Container>
      </Navbar>
      <Container>
        <Row className='row-cols-2 row-cols-md-3 g-4 py-4' >
          {
            props.isLoggedIn ?
              <IntegrationList
                integrations={props.integrations}
                isLoading={props.isLoading}
                onDelete={onDelete}
                onEditClick={
                  (integration) => {
                    handleShow(integration);
                  }
                }
              />
              :
              null
          }

        </Row>
      </Container>

      <Offcanvas style={{width: "600px"}} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Configurar integración</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ConfigForm
            integration={integration}
            isProcessing={props.isProcessing}
            onCreate={onCreate}
            onUpdate={onUpdate}
            access_token={props.access_token}
          ></ConfigForm>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default withLoginInitiator(
  withIntegrationsCreator(
    withIntegrationsGetter(App)
  ),
  {
    // Para desarrollar en local, usar la configuración de 'redirect_url' que
    // viene en la siguiente línea ↓↓↓
    // redirect_url: "http://localhost:3000/",
    redirect_url: 'https://lpiproduct.loadingplay.com/',
    client_id: "460",
    site_name: "baymax",
    client_secret: "4e05fbab488c0e577679b498e58a1b75"
  }
);
