import React from 'react';


function withLoginInitiator(WrappedComponent, config) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoggedIn: false,
        isLoading: true,
        site_name: '',
        access_token: ''
      };
    }

    componentDidMount = () => {
      if (document.location.href.indexOf("site_name") !== -1)
        window.close();
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} initLogin={this.initLogin} />;
    }

    initLogin = async () => {
      const data = {
        redirect_uri: config.redirect_url,
        site_name: config.site_name,
        client_id: config.client_id,
        response_type: 'code'
      };
      const uri_encoded = this._encodeURI(data);
      const url = `https://accounts.loadingplay.com/oauth2/auth?${uri_encoded}`;

      const win = window.open(url, '_blank');
      win.onbeforeunload = this._beforeUnload;
    }

    _encodeURI = (data) => {
      return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
    }

    _beforeUnload = async (e) => {
      const selected_site = e.target.location.href.split('&')[0].split('=')[1];
      const code = e.target.location.href.split('&')[1].split('=')[1];
      const data = {
        code: code,
        grant_type: "authorization_code",
        client_id: config.client_id,
        client_secret: config.client_secret,
        redirect_uri: config.redirect_url
      };

      const response = await fetch(
        "https://accounts.loadingplay.com/oauth2/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: this._encodeURI(data)
        }
      );

      const json = await response.json();

      this.setState({
        isLoading: false,
        isLoggedIn: true,
        site_name: selected_site,
        access_token: json.access_token
      });
    }

  };
}

export default withLoginInitiator;
