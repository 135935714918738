import {
  Card,
  Button
} from 'react-bootstrap'

function IntegrationCard(props) {
  return (
    <Card className='w-100' style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>{props.integration.common.site_name}</Card.Title>
        <Card.Text>
          {props.integration.status}
        </Card.Text>
        <Button
          onClick={() => props.onEditClick(props.integration)}
          variant="primary"
        >Editar</Button>
        <Button
          variant="danger"
          onClick={() => {
            if (window.confirm(`¿está seguro de eliminar la integración de ${props.integration.common.site_name}?`))
              props.onDelete(props.integration);
          }}
        >Eliminar</Button>
      </Card.Body>
    </Card>
  )
}

export default IntegrationCard
