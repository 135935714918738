import React from 'react';

import { Col } from 'react-bootstrap';
import IntegrationCard from './integrationcard';


const IntegrationList = (props) => {
  if (props.isLoading)
    return <div>Loading...</div>;

  return (
    <>
      {
        props.integrations.map((integration) => {
          return (
            <Col key={integration.id}>
              <IntegrationCard integration={integration} {...props} />
            </Col>
          )
        })
      }
    </>
  )
}

export default IntegrationList;
