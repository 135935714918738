import { Form } from 'react-bootstrap';

import withSiteLoader from '../containers/withsiteloader';


function SiteSelector(props) {

  return (
    <>
      <Form.Control
        type="select"
        placeholder="Nombre del sitio en Loadingplay"
        defaultValue={props.site_name}
        onChange={props.onChange}
        name="site_name"
        list="datalistOptions"
      />
      <datalist id="datalistOptions">
        { props.sites.map((e) => <option key={e.id} value={e.name} />) }
      </datalist>
    </>
  );
}


export default withSiteLoader(SiteSelector);
