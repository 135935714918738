import {
  Form,
  InputGroup
} from 'react-bootstrap'

function ConfigShopify(props) {
  const default_json = {
    access_token: props.access_token,
    site_name: props.site_name,
    create_product_shopify: props.create_product_shopify
  };

  const onChange = (e) => {
    const value = e.target.value.trim().replace(" ", "");
    default_json[e.target.name] = value;
    document.getElementsByName(e.target.name)[0].value = value;
    props.onChange(default_json);
  }
  const onChangeCreateProductShopify = (e) => {
    default_json[e.target.name] = e.target.checked;
    props.onChange(default_json);
  }

  return (<>
    <h6>Configuración Shopify</h6>

    <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check
          type="switch"
          id="custom-switch"
          name='create_product_shopify'
          checked={props.create_product_shopify}
          onChange={onChangeCreateProductShopify}
          label={props.create_product_shopify ? "Crear producto habilitada":"Crear producto deshabilitada"}
        />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formShopifyAccessToken">
      <Form.Label>Access token</Form.Label>
      <Form.Control type="text" placeholder="sshd_asd89asd789as7d897as89d7" defaultValue={props.access_token} name="access_token" onChange={onChange} />
    </Form.Group>

    <Form.Group className="mb-3" controlId="formShopifySiteName">
      <Form.Label>Nombre del sitio</Form.Label>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="basic-addon2">https://</InputGroup.Text>
        <Form.Control type="text" placeholder="mysite" defaultValue={props.site_name} name="site_name" onChange={onChange} />
        <InputGroup.Text id="basic-addon2">.myshopify.com</InputGroup.Text>
      </InputGroup>
    </Form.Group>
  </>);
}


export default ConfigShopify;
