import React from 'react';


const withCompanyDataLoader = (Component) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        company: {}
      }
    }

    componentDidMount = () => {
      this.loadCompanyData();
    }

    componentDidUpdate = (prevProps) => {
      if (prevProps.access_token !== this.props.access_token)
        this.loadCompanyData();
    }

    loadCompanyData = async () => {
      try{
        const response = await fetch(
          `https://credential.bsale.io/v1/instances/basic/${this.props.access_token}.json`,
          {
            method: "GET",
            cache: 'no-store',
            headers: {
              "access_token": this.props.access_token,
              "Content-Type": "application/json"
            }
          }
        );
        if (response.status !== 200 && response.status !== 201) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        const company = await response.json();
        this.setState({company});
      }
      catch(error) {
        this.setState({company: {}});
      }
    }

    render = () => {
      return (
        <Component
          company={this.state.company}
          {...this.props}
          onTokenUpdate={this.loadCompanyData}
        ></Component>
      );
    }
  }
}

export default withCompanyDataLoader;
