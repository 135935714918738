import { Form } from 'react-bootstrap';


function BsalePriceTaxesSelector(props) {

  const onChange = (e) => {
    props.onChange({
      "target": {
        "value": e.target.checked,
        "name": "use_price_with_taxes"
      }
    });
  }

  return (
    <Form.Check
      type="switch"
      id="switch-use-price-with-taxes"
      name='use_price_with_taxes'
      checked={props.use_price_with_taxes}
      onChange={onChange}
      label={props.use_price_with_taxes ? "Usar precios con impuestos" : "Usar precios sin impuestos"}
    />
  )
}


export default BsalePriceTaxesSelector;
