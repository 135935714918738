import React from 'react';

import { Button } from 'react-bootstrap';

import ConfigBsale from './configbsale';
import ConfigCommon from './configcommon';
import ConfigShopify from './configshopify';

function ConfigForm(props) {

  const [integration, setIntegration] = React.useState(props.integration);

  const onChangeIntegration = (config_name, new_config) => {
    setIntegration(
      {
        ...integration,
        [config_name]: { ...integration[config_name], ...new_config }
      }
    );
  }

  const onChangeCommon = (new_config) => {
    onChangeIntegration('common', new_config);
  }

  const onChangeBsale = (new_config) => {
    onChangeIntegration('bsale', new_config);
  }

  const onChangeShopify = (new_config) => {
    onChangeIntegration('shopify', new_config);
  }

  return (
    <>

      <ConfigCommon {...integration.common} access_token={props.access_token} onChange={onChangeCommon} />
      <hr />
      <ConfigBsale {...integration.bsale} site_name={integration.common?.site_name} onChange={onChangeBsale} />
      <hr />
      <ConfigShopify {...integration.shopify} onChange={onChangeShopify} />

      <hr />
      <Button
        className='w-100'
        variant="primary"
        type="button" onClick={
          () => {
            if (integration.id === undefined)
              props.onCreate(integration);
            else
              props.onUpdate(integration);
          }
        }
        disabled={props.isProcessing}
      >
        { props.isProcessing ? "Enviando...":"Crear/Actualizar" }
      </Button>
    </>
  );
}


export default ConfigForm;
