import React from 'react';


const withPriceListLoader = (Component) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        price_lists: []
      }
    }

    componentDidMount = () => {
      this.loadPriceLists();
    }

    componentDidUpdate = (prevProps) => {
      if (prevProps.access_token !== this.props.access_token)
        this.loadPriceLists();
    }

    loadPriceLists = async () => {
      try{
        const response = await fetch(
          "https://api.bsale.cl/v1/price_lists.json?state=0",
          {
            method: "GET",
            cache: 'no-store',
            headers: {
              "access_token": this.props.access_token,
              "Content-Type": "application/json"
            }
          }
        );
        const json = await response.json();
        const price_lists = json.items.map(
          (item) => {
            return {
              id: item.id,
              name: item.name
            }
          }
        );

        this.setState({price_lists});
      }
      catch(error) {
        this.setState({price_lists: []});
      }
    }

    render = () => {
      return (
        <Component
          price_lists={this.state.price_lists}
          {...this.props}
          onTokenUpdate={this.loadPriceLists}
        ></Component>
      );
    }
  }
}

export default withPriceListLoader;
