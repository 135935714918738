import { Form } from 'react-bootstrap';
import SiteSelector from './siteselector';


function ConfigCommon(props) {

  const onChangeSiteName = (e) => {
    props.onChange({site_name: e.target.value});
  }

  const constOnChangeEnabled = (e) => {
    props.onChange({enabled: e.target.checked});
  }

  return (
    <>
      <h6>Configuración común</h6>
      <Form.Group className="mb-3" controlId="formSiteName">
        <Form.Label>Sitio</Form.Label>
        <SiteSelector
          site_name={props.site_name}
          access_token={props.access_token}
          onChange={onChangeSiteName}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check
          type="switch"
          id="custom-switch"
          name='enabled'
          checked={props.enabled}
          onChange={constOnChangeEnabled}
          label={props.enabled ? "Integración habilitada":"Integración deshabilitada"}
        />
      </Form.Group>
    </>
  );
}


export default ConfigCommon;
