import React from "react";


const withSiteLoader = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        sites: [],
        isLoading: true
      };
    }

    componentDidMount = async () => {
      const response = await fetch(
        "https://accounts.loadingplay.com/v1/site", {
          "headers": {
            "authorization": `Bearer ${this.props.access_token}`,
          },
          "referrer": "https://bodegas.loadingplay.com/",
          "referrerPolicy": "strict-origin-when-cross-origin",
          "method": "GET",
          "mode": "cors"
        }
      );
      const json = await response.json();
      this.setState({ sites: json.sites, isLoading: false });
    }

    render() {
      return <WrappedComponent {...this.props} sites={this.state.sites} isLoading={this.state.isLoading} />;
    }
  }
};


export default withSiteLoader;
