class IntegrationsGetter {

  constructor(access_token) {
    this.access_token = access_token
  }

  async list() {
    const response = await fetch(
      "https://lpiproduct-o7untflc2a-uc.a.run.app/configurations/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.access_token
        }
      }
    );
    const json_data = await response.json();
    return json_data.items;
  }
}

export default IntegrationsGetter;
