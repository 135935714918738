import { Form } from 'react-bootstrap';
import BsaleHook from './bsalehook';
import BsalePriceListSelector from './bsalepricelistselector';
import BsalePriceTaxesSelector from './bsalepricetaxesselector';
import withPriceListLoader from '../containers/withpricelistloader';
import withCompanyDataLoader from '../containers/withCompanyDataLoader';


function ConfigBsale(props) {

  const default_json = {
    access_token: props.access_token,
    price_list_id: props.price_list_id
  };

  const onChange = (e) => {
    default_json[e.target.name] = e.target.value;
    props.onChange(default_json);
  }

  return (
    <>
      <h6>Configuración Bsale</h6>

      <Form.Group className="mb-3" controlId="formBsaleAccessToken">
        <Form.Label>Access token</Form.Label>
        <Form.Control
          type="text"
          placeholder="asd89asd789as7d897as89d7"
          name="access_token"
          defaultValue={props.access_token}
          onChange={onChange}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBsalePriceListId">
        <Form.Label>Id Lista de precio</Form.Label>
        <BsalePriceListSelector
          selected={props.price_list_id}
          price_lists={props.price_lists}
          onChange={onChange}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <BsalePriceTaxesSelector
          use_price_with_taxes={props.use_price_with_taxes}
          onChange={onChange}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBsalePriceListId">
        <Form.Label>Webhook</Form.Label>
        <BsaleHook site_name={props.site_name} company={props.company} />
      </Form.Group>

    </>
  );
}


export default withCompanyDataLoader(
  withPriceListLoader(
    ConfigBsale
  )
);
