class IntegrationsCreator {

  constructor(access_token) {
    this.access_token = access_token
    this.url_lpiproducts_prod = "https://lpiproduct-o7untflc2a-uc.a.run.app"
  }

  create(integration) {
    // default values
    const default_json = {
      common: {
        site_name: "",
        enabled: true,
      },
      shopify: {
        site_name: "",
        access_token: "",
        create_product_shopify: false
      },
      bsale: {
        access_token: "",
        price_list_id: 0
      }
    };

    return fetch(`${this.url_lpiproducts_prod}/configurations/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.access_token
      },
      body: JSON.stringify({...default_json, ...integration})
    });
  }

  update(integration) {
    return fetch(`${this.url_lpiproducts_prod}/configurations/${integration.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.access_token
      },
      body: JSON.stringify(integration)
    });
  }

  delete(integration) {
    return fetch(`${this.url_lpiproducts_prod}/configurations/${integration.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.access_token
      }
    });
  }
}


export default IntegrationsCreator;
